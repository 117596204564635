<template>
  <div class="home">
    <Header />
    <section class="col-xl-12 pb-5 col-lg-12" style="padding-top: 150px">
      <div class="container">
        <div class="container grid grid-2">
          <!-- Left Carousel -->
          <div class="container left">
            <div class="carousel">
              <div class="carousel-img">
                <img src="../assets/img/bg-iphone-home.png" alt="" />
              </div>
              <div class="carousel-content">
                <h1 class="carousel-content-title text-left">
                  {{ $t("home.euro-title") }}
                </h1>
                <p class="carousel-content-text text-left">
                  {{ $t("home.euro-content-desk") }}
                </p>
              </div>
            </div>
            <!-- Statistic -->
            <div class="container mt-3">
              <div class="title-bar flex mb-2" style="justify-content: left">
                <div class="title">{{ $t("statistics.win-rate-chart") }}</div>
              </div>
              <div class="carousel">
                <div class="flex card-matches-content">
                  <div style="position: relative; height: 450px; width: 450px">
                    <canvas id="myChart" width="400" height="400"></canvas>
                  </div>
                </div>
              </div>
            </div>
            <!-- Statics Result -->
            <div class="container mt-3">
              <div class="container">
                <div class="title-bar flex mb-2" style="justify-content: left">
                  <div class="title">
                    {{ $t("statistics.win-rate-chart-result") }}
                  </div>
                </div>
                <div
                  class="card-matches card-bg-grey"
                  style="font-size: 18px; margin: 15px"
                >
                  <div class="card-matches-title"></div>
                  <div class="col mt-3">
                    {{ $t("nav.win") }}: ({{
                      matchSetting && matchSetting.total_win
                        ? matchSetting.total_win
                        : "-"
                    }}) {{ $t("nav.rate") }}:
                    {{
                      (matchSetting && matchSetting.total_win
                        ? matchSetting.total_win_rate
                        : 0) + "%"
                    }}
                    <div class="progress">
                      <div
                        class="progress-bar bg-danger"
                        role="progressbar"
                        :style="
                          'width: ' +
                          (matchSetting && matchSetting.total_win
                            ? matchSetting.total_win_rate
                            : 0) +
                          '%'
                        "
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="col mt-3">
                    {{ $t("nav.lose") }}: ({{
                      matchSetting && matchSetting.total_lose
                        ? matchSetting.total_lose
                        : "-"
                    }}) {{ $t("nav.rate") }}:
                    {{
                      (matchSetting && matchSetting.total_win
                        ? matchSetting.total_lose_rate
                        : 0) + "%"
                    }}
                    <div class="progress">
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        :style="
                          'width: ' +
                          (matchSetting && matchSetting.total_win
                            ? matchSetting.total_lose_rate
                            : 0) +
                          '%'
                        "
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="col mt-3">
                    {{ $t("nav.draw") }}: ({{
                      matchSetting && matchSetting.total_draw
                        ? matchSetting.total_draw
                        : "-"
                    }}) {{ $t("nav.rate") }}:
                    {{
                      (matchSetting && matchSetting.total_win
                        ? matchSetting.total_draw_rate
                        : 0) + "%"
                    }}
                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        :style="
                          'width: ' +
                          (matchSetting && matchSetting.total_win
                            ? matchSetting.total_draw_rate
                            : 0) +
                          '%'
                        "
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="col mt-3">
                    <span> {{ $t("statistics.total-match") }}: </span>
                    <span class="hightlight-text">
                      {{
                        matchSetting && matchSetting.total_match
                          ? matchSetting.total_match
                          : "-"
                      }}</span
                    >&nbsp;
                    <span
                      >&nbsp;&nbsp; {{ $t("statistics.total-win-rate") }}:
                    </span>
                    <span class="hightlight-text">
                      {{
                        matchSetting && matchSetting.total_win_rate
                          ? matchSetting.total_win_rate
                          : "-"
                      }}
                      %</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Right Container -->
          <div class="container right" v-if="matchResult">
            <!-- Banner -->
            <div
              v-for="(banner, key) in bannerTop"
              :key="key"
              class="mt-3 mb-2"
            >
              <a :href="banner.target_url" target="_blank"
                ><img style="width: 100%" :src="banner.path"
              /></a>
            </div>

            <div class="container">
              <div class="title-bar flex" style="justify-content: left">
                <div class="title margin-right-auto">
                  {{ $t("home.win-rate") }}
                </div>
                <div class="see-all"></div>
              </div>
              <div class="card-matches card-bg-blue">
                <div class="grid grid-3-win-rate win-rate-bar">
                  <div>
                    {{ $t("statistics.total-match") }}:
                    <span class="val">
                      {{
                        matchSetting && matchSetting.total_match
                          ? matchSetting.total_match
                          : "-"
                      }}</span
                    >
                  </div>
                  <div>
                    {{ $t("statistics.total-win") }}:
                    <span class="val">
                      {{
                        matchSetting && matchSetting.total_win
                          ? matchSetting.total_win
                          : "-"
                      }}</span
                    >
                  </div>
                  <div>
                    <i class="fas fa-fire text-danger"></i>
                    {{ $t("statistics.total-win-rate") }} :
                    <span class="val win-rate-blink">
                      {{
                        matchSetting && matchSetting.total_win_rate
                          ? matchSetting.total_win_rate
                          : "-"
                      }}
                      %</span
                    >
                  </div>
                </div>
                <div class="grid grid-3-win-rate win-rate-bar">
                  <div>
                    {{ $t("statistics.total-draw") }} :
                    <span class="val">
                      {{
                        matchSetting && matchSetting.total_draw
                          ? matchSetting.total_draw
                          : "-"
                      }}</span
                    >
                  </div>
                  <div>
                    {{ $t("statistics.total-lose") }} :
                    <span class="val">
                      {{
                        matchSetting && matchSetting.total_lose
                          ? matchSetting.total_lose
                          : "-"
                      }}</span
                    >
                  </div>
                  <div>
                    <i class="fas fa-fire text-danger"></i>
                    {{ $t("statistics.total-weekly-win-rate") }} :
                    <span class="val win-rate-blink">
                      {{
                        matchSetting && matchSetting.total_weekly_win_rate
                          ? matchSetting.total_weekly_win_rate
                          : "-"
                      }}%</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Upcoming matches -->
            <div class="container grid grid-3" v-if="dataMatchObj">
              <div class="content">
                <div class="title-bar flex">
                  <div class="title margin-right-auto">
                    {{ $t("home.upcoming-matches") }}
                  </div>
                  <div class="see-all">
                    <a href="/recommend"> {{ $t("home.see-all") }} > </a>
                  </div>
                </div>

                <div class="container">
                  <div class="container grid grid-2">
                    <div
                      class="card-matches card-bg-blue"
                      v-for="(item, index) in dataMatchObj"
                      :key="index"
                    >
                      <div class="card-matches-title">
                        {{ item.league_name }}
                      </div>
                      <div class="card-matches-content grid grid-4-matches">
                        <div class="home-team text-center">
                          <img src="../assets/img/soccer.png" alt="" />
                          <span class="team-title"> {{ item.home_team }}</span>
                        </div>
                        <div class="result-live text-center">
                          <!-- <div class="live-text text-danger">
                            <span class="live">Live</span>
                          </div> -->
                          <div class="live-time">
                            <a
                              href=""
                              class="
                                btn btn-block btn-sm btn-danger
                                font-weight-bold
                              "
                            >
                              {{ item.open_date }}</a
                            >
                            <a
                              href=""
                              class="
                                btn btn-block btn-sm btn-live-time
                                font-weight-bold
                              "
                            >
                              {{ item.open_time }}</a
                            >
                          </div>
                        </div>
                        <div class="away-team text-center">
                          <img src="../assets/img/soccer.png" alt="" />
                          <span class="team-title">{{ item.away_team }}</span>
                        </div>
                        <div class="modal-button">
                          <a href="" class="btn btn-sm btn-block handicap">
                            {{ $t("nav.handicap") }} {{ item.handicap }}</a
                          >
                          <a
                            href="#"
                            @click="toggleModal"
                            :data-id="index"
                            class="btn btn-sm btn-block btn-live-modal mt-2"
                          >
                            {{ $t("home.unravel") }}</a
                          >
                          <a
                            @click="toggleModalReal"
                            href="#"
                            :data-id="index"
                            class="btn btn-sm btn-block btn-live-modal mt-2"
                          >
                            {{ $t("home.real-order") }}</a
                          >
                        </div>
                      </div>

                      <div class="card-matches-footer">
                        <div class="left">
                          <a
                            target="_blank"
                            :href="whatsappLink"
                            style="color: white"
                          >
                            {{ $t("nav.click-here") }}
                            <img
                              src="../assets/img/whatsapp_green.png"
                              alt=""
                              style="
                                width: 40px;
                                height: 40px;
                                display: inline-block;
                              "
                            />
                            {{ $t("nav.recom") }}:
                            <span class="hightlight-text">{{
                              item.free_analysis
                            }}</span>
                          </a>
                        </div>
                        <div class="right win"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Match Result -->
            <div class="container grid grid-3">
              <!-- <div class="left-arrow-button">
                <div class="bg-button-arrow btn-reverse">
                  <i class="fas fa-play"></i>
                </div>
              </div> -->

              <div class="content">
                <div class="title-bar flex">
                  <div class="title margin-right-auto">
                    {{ $t("home.match-result") }}
                  </div>
                  <div class="see-all">
                    <a href="/match-result"> {{ $t("home.see-all") }} ></a>
                  </div>
                </div>

                <div class="container">
                  <div class="container grid grid-2">
                    <div
                      class="card-matches card-bg-blue"
                      v-for="(item, index) in dataMatchResultObj"
                      :key="index"
                    >
                      <div class="card-matches-title">
                        {{ item.league_name }}
                      </div>
                      <div class="card-matches-content grid grid-4-matches">
                        <div class="home-team text-center">
                          <img src="../assets/img/soccer.png" alt="" />
                          <span class="team-title">{{ item.home_team }}</span>
                        </div>
                        <div
                          class="result-live text-center"
                          style="margin-top: 0px"
                        >
                          <h1 class="font-weight-bold">
                            {{ item.home_team_score || "?" }} :
                            {{ item.away_team_score || "?" }}
                          </h1>
                          <a
                            href=""
                            class="
                              btn btn-block btn-sm btn-danger
                              font-weight-bold
                            "
                          >
                            {{ item.open_date }}</a
                          >
                        </div>
                        <div class="away-team text-center">
                          <img src="../assets/img/soccer.png" alt="" />
                          <span class="team-title"> {{ item.away_team }}</span>
                        </div>
                        <div class="modal-button">
                          <a href="" class="btn btn-sm btn-block handicap">
                            {{ $t("nav.handicap") }} {{ item.handicap }}</a
                          >
                          <a
                            href="#"
                            @click="toggleModalMatch"
                            :data-id="index"
                            class="btn btn-sm btn-block btn-live-modal mt-2"
                          >
                            {{ $t("home.unravel") }}</a
                          >
                          <a
                            href="#"
                            @click="toggleModalMatchReal"
                            :data-id="index"
                            class="btn btn-sm btn-block btn-live-modal mt-2"
                          >
                            {{ $t("home.real-order") }}</a
                          >
                        </div>
                      </div>

                      <div class="card-matches-footer flex">
                        <div class="left margin-right-auto">
                          {{ $t("nav.free-recom") }}:
                          <span class="hightlight-text">{{
                            item.free_analysis
                          }}</span>
                        </div>
                        <div
                          class="right text-primary font-weight-bold"
                          style="text-transform: capitalize"
                        >
                          <span
                            class="text-right"
                            :class="
                              item.real_order_handicap_win_or_lose == 'win'
                                ? 'win-or-lose'
                                : ''
                            "
                            style="
                              display: block;
                              font-size: 16px;
                              font-weight: bold;
                            "
                          >
                            {{
                              item.real_order_handicap_win_or_lose_display_id
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="right-arrow-button">
                <div class="bg-button-arrow">
                  <i class="fas fa-play"></i>
                </div>
              </div> -->
            </div>

            <!-- Experience -->
            <div class="container top-container">
              <div class="content">
                <div class="container">
                  <div class="title-bar flex">
                    <div class="title margin-right-auto">
                      {{ $t("experience.title") }}
                    </div>
                    <div class="see-all"></div>
                  </div>
                  <div class="card-matches card-bg-blue">
                    <!-- <div class="card-matches-title">2021-06-15</div> -->
                    <div class="card-matches-content" style="overflow: auto">
                      <p>
                        <span
                          ><h4>{{ $t("experience.sub-title") }}:</h4></span
                        >
                      </p>
                      <p>
                        {{ $t("experience.para-one") }}
                      </p>
                      <p>
                        {{ $t("experience.para-two") }}
                      </p>
                      <p>
                        {{ $t("experience.para-three") }}
                      </p>
                      <p>
                        {{ $t("experience.para-four") }}
                      </p>

                      <p>
                        {{ $t("experience.para-fifth") }}
                      </p>

                      <p>
                        <span
                          ><h4>{{ $t("experience.water-supply") }}</h4></span
                        >
                      </p>

                      <p>
                        {{ $t("experience.para-water") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Whatsapp -->
            <div class="card-matches card-bg-blue">
              <div class="card-matches-title"></div>
              <div
                class="card-matches-content"
                style="overflow: auto; font-size: 20px"
              >
                <a target="_blank" :href="whatsappLink">
                  <span style="display: inline-block; color: white">{{
                    $t("home.join-whats")
                  }}</span
                  >&nbsp; &nbsp;
                  <img
                    src="../assets/img/whatsapp_green.png"
                    alt=""
                    style="width: 40px; height: 40px; display: inline-block"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal ref="my-modal" size="xl" hide-footer :title="$t('home.unravel')">
      <div class="col-lg-12 pb-2 alert fs-12">
        {{ currentUnravel }}
      </div>
      <button
        class="btn btn-info mt-2 mr-2"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("nav.close") }}
      </button>
    </b-modal>

    <b-modal ref="my-modal-real-order" size="xl" hide-footer :title="$t('home.real-order')">
      <div class="col-lg-12 pb-2 alert fs-12" v-if="currentRealOrder">
        {{ currentRealOrder }}
      </div>

      <table
        class="table"
        v-if="
          currentRealOrderDetail &&
          currentRealOrderDetail.real_order_home_team_score
        "
      >
        <tr>
          <td>{{ currentRealOrderDetail.real_order_handicap }}</td>
          <td>{{ currentRealOrderDetail.real_order_league }}</td>
          <td>
            {{ currentRealOrderDetail.home_team }} vs
            {{ currentRealOrderDetail.away_team }} (0:0) <br />{{
              currentRealOrderDetail.real_order_handicap
            }}
            <br />
            {{ currentRealOrderDetail.home_team }} -
            {{ currentRealOrderDetail.real_order_home_team_score }} @
            {{ currentRealOrderDetail.real_order_home_team_handicap }} ({{
              $t("nav.finale")
            }}: {{ currentRealOrderDetail.real_order_home_team_score }}-
            {{ currentRealOrderDetail.real_order_away_team_score }})
          </td>
          <td>{{ currentRealOrderDetail.real_order_handicap_one }}</td>
          <td>{{ currentRealOrderDetail.real_order_handicap_two }}</td>
          <td>{{ currentRealOrderDetail.real_order_handicap_three }}</td>
          <td class="text-capitalize">
            {{
              currentRealOrderDetail.real_order_handicap_win_or_lose_display_id
            }}
          </td>
        </tr>
      </table>

      <button
        class="btn btn-info mt-2 mr-2"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("nav.close") }}
      </button>
    </b-modal>
    <Footer />
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    "b-modal": BModal,
    Header,
    Footer,
  },
  data() {
    return {
      limit: 2, //at most for match display
      matchSetting: null,
      matchDetail: null,
      currentHost: "https://72963.com",
      bannerHost: "https://72963.com",
      bannerSettings: [
        {
          title: "pc top",
          category: 1,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
        {
          title: "72963-pc",
          category: 1,
          status: 1,
          target_url: "https://77577.com",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/banner-77577-desktop.png",
          width: 970,
          height: 150,
        },
      ],
      matchResult: null,
      currentUnravel: "",
      currentRealOrder: "",
      currentRealOrderDetail: "",
    };
  },
  props: {
    msg: String,
  },
  computed: {
    bannerTop() {
      if (this.bannerSettings != null) {
        return this.bannerSettings.filter(function (item) {
          return item.category == 1;
          //return item;
        });
      } //

      return "";
    },
    whatsappLink() {
      var text = "https://wa.me/";
      var number_locale = this.$i18n.locale == "vi" ? "_vi" : "";
      var number =
        this.matchSetting &&
        this.matchSetting["whatsapp_number" + number_locale]
          ? this.matchSetting["whatsapp_number" + number_locale]
          : "";
      return text + number;
    },
    dataMatchObj() {
      if (this.matchDetail) {
        //const today = new Date().getTime();
        const filterByExpiration = this.matchDetail.filter((items) => {
          // return (
          //   new Date(items.open_date + " " + items.open_time).getTime() > today
          // );
          return !items.home_team_score;
        });

        return filterByExpiration
          ? filterByExpiration.splice(0, this.limit)
          : [];
      }
      return [];
    },
    dataMatchResultObj() {
      if (this.matchDetail) {
        // const today = new Date().getTime();
        const filterByExpiration = this.matchDetail.filter((items) => {
          // return (
          //   new Date(items.open_date + " " + items.open_time).getTime() <= today
          // );
          return items.home_team_score;
        });

        return filterByExpiration
          ? filterByExpiration.splice(0, this.limit)
          : [];
      }
      return [];
    },
  },
  created() {
    axios
      .get(`${this.bannerHost}/api/banner/`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});

    //Api setting detail
    var _this = this;
    axios
      .get(`${this.bannerHost}/api/setting-detail`)
      .then((response) => {
        this.matchSetting = response.data.data;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
    //Api match detail
    axios
      .get(`${this.bannerHost}/api/match-detail`)
      .then((response) => {
        this.matchDetail = response.data.data;
        this.matchResult = response.data.data;
        setTimeout(function () {
          _this.showChart();
        }, 500);
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  mounted() {},
  methods: {
    showChart() {
      this.$nextTick(function () {
        var Chart = require("chart.js");
        var ctx = document.getElementById("myChart");
        var total_win_rate = this.matchSetting
          ? this.matchSetting.total_win_rate
          : 0;
        var total_lose_rate = this.matchSetting
          ? this.matchSetting.total_lose_rate
          : 0;
        var total_draw_rate = this.matchSetting
          ? this.matchSetting.total_draw_rate
          : 0;

        // For a pie chart
        new Chart(ctx, {
          type: "pie",
          data: {
            labels: [
              this.$t("nav.win") + "(" + total_win_rate + "%)",
              this.$t("nav.draw") + "(" + total_draw_rate + "%)",
              this.$t("nav.lose") + "(" + total_lose_rate + "%)",
            ],
            datasets: [
              {
                label: "Win,Lose and Draw",
                backgroundColor: ["#dc3545", "#28a745", "#17a2b8"],
                data: [total_win_rate, total_lose_rate, total_draw_rate],
              },
            ],
          },
          options: {
            legend: {
              labels: {
                // This more specific font property overrides the global property
                fontColor: "white",
                fontSize: 16,
              },
            },
            title: {
              display: true,
              text: "",
            },
          },
        });
      });
    },
    toggleModal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var handicap_name = this.$i18n.locale == "vi" ? "handicap_desc_vi" : "handicap_desc";
      var handicap_desc = this.dataMatchObj[id][handicap_name] != null && this.dataMatchObj[id][handicap_name] != ''? this.dataMatchObj[id][handicap_name]:this.dataMatchObj[id]['handicap_desc'];
      this.currentUnravel = handicap_desc;
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].show();
    },
    toggleModalReal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var real_order_name_default = 'real_order';
      var real_order_name = this.$i18n.locale == "vi" ? "real_order_vi" : "real_order";
      var real_order = this.dataMatchObj[id][real_order_name] != null && this.dataMatchObj[id][real_order_name] != ''? this.dataMatchObj[id][real_order_name]:this.dataMatchObj[id][real_order_name_default];
      this.currentRealOrder = real_order;
      this.currentRealOrderDetail = this.dataMatchObj[id];
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-real-order"].show();
    },
    toggleModalMatch(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var handicap_name = this.$i18n.locale == "vi" ? "handicap_desc_vi" : "handicap_desc";
      var handicap_desc = this.dataMatchResultObj[id][handicap_name] != null && this.dataMatchResultObj[id][handicap_name] != ''? this.dataMatchResultObj[id][handicap_name]:this.dataMatchResultObj[id]['handicap_desc'];
      this.currentUnravel = handicap_desc;
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].show();
    },
    toggleModalMatchReal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var real_order_name_default = 'real_order';
      var real_order_name = this.$i18n.locale == "vi" ? "real_order_vi" : "real_order";
      var real_order = this.dataMatchResultObj[id][real_order_name] != null && this.dataMatchResultObj[id][real_order_name] != ''? this.dataMatchResultObj[id][real_order_name]:this.dataMatchResultObj[id][real_order_name_default];
      this.currentRealOrder = real_order;
      this.currentRealOrderDetail = this.dataMatchResultObj[id];
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-real-order"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$refs["my-modal-real-order"].hide();
    },
  },
};
</script>

<style scoped>
.card-matches p {
  font-size: 18px;
  word-spacing: 2px;
}

.card-matches h4 {
  font-weight: 600;
  text-shadow: 3px 5px 2px #474747;
}
</style>
